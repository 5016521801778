import Swal from "sweetalert2";
import palette from "../styles/light-theme/palette";

const colors = {
  success: {
    200: "#edf5ef",
    500: "#55BC69",
  },
  warning: {
    200: "#fcf8ed",
    500: "#fed06d",
  },
  error: {
    200: "#fae6e3",
    500: "#FF6347",
  },
  secondary: "#d4d4d8",
};

const ToastConfirm = Swal.mixin({
  customClass: {
    title: "text-uppercase fs-4 fw-bolder",
    confirmButton: "text-white py-2 px-5 rounded-3 fw-bold",
    cancelButton: "fw-bold text-black py-2 px-5 rounded-3",
    actions: "w-100",
    popup: "rounded-4",
    htmlContainer: "shadow-xl m-0 fs-6 text-muted",
  },
  allowEscapeKey: false,
  showClass: {
    popup: `
          animate__animated
          animate__zoomIn
          animate__faster
        `,
  },
  hideClass: {
    popup: `
          animate__animated
          animate__zoomOut
          animate__faster
        `,
  },
});

const Toast = Swal.mixin({
  toast: true,
  showConfirmButton: false,
  timer: 2800,
  timerProgressBar: false,
  showCloseButton: false,
  customClass: {
    popup: "rounded-3 shadow",
  },
  showClass: {
    popup: `
      animate__animated
      animate__fadeInRight
      animate__faster
    `,
  },
  hideClass: {
    popup: `
      animate__animated
      animate__fadeOutRight
      animate__faster
    `,
  },
  didOpen: (toast) => {
    toast.onclick = Swal.close;
  },
  position: "top-end",
});

class Notification {
  cadastroSucesso() {
    Toast.fire({
      icon: "success",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.success[500]} 2%)`,
      iconColor: colors.success[500],
      color: palette.black,
      title: "Cadastro realizado com sucesso",
    });
  }

  alteracaoSucesso() {
    Toast.fire({
      icon: "success",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.success[500]} 2%)`,
      iconColor: colors.success[500],
      color: palette.black,
      title: "Alteração enviada com sucesso",
    });
  }

  deletadoSucesso() {
    Toast.fire({
      icon: "success",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.success[500]} 2%)`,
      iconColor: colors.success[500],
      color: palette.black,
      title: "Removido com sucesso",
    });
  }

  erroValidacao(campo) {
    Toast.fire({
      icon: "warning",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.warning[500]} 2%)`,
      iconColor: colors.warning[500],
      color: palette.black,
      title: `${campo} é obrigatório`,
    });
  }

  erroCadastroApi(title) {
    Toast.fire({
      icon: "error",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.error[500]} 2%)`,
      iconColor: colors.error[500],
      color: palette.black,
      title,
    });
  }

  alertaApi(title) {
    Toast.fire({
      icon: "warning",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.warning[500]} 2%)`,
      iconColor: colors.warning[500],
      color: palette.black,
      title,
    });
  }

  alertaGenericos(title) {
    Toast.fire({
      icon: "warning",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.warning[500]} 2%)`,
      iconColor: colors.warning[500],
      color: palette.black,
      title,
    });
  }

  sucessoGenericos(title) {
    Toast.fire({
      icon: "success",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.success[500]} 2%)`,
      iconColor: colors.success[500],
      color: palette.black,
      title,
    });
  }

  erroInternoServidor() {
    Toast.fire({
      icon: "error",
      background: `linear-gradient(90deg, ${palette.white} 98%, ${palette.white} 98%, ${colors.error[500]} 2%)`,
      iconColor: colors.error[500],
      color: palette.black,
      title: "Erro no servidor. Tente novamente mais tarde.",
    });
  }

  erroGenericos(title) {
    Toast.fire({
      icon: "error",
      background: `linear-gradient(90deg, ${colors.error[200]} 98%, ${colors.error[200]} 98%, ${colors.error[500]} 2%)`,
      iconColor: colors.error[500],
      color: palette.black,
      title,
    });
  }

  async confirmacao(title, text) {
    return await ToastConfirm.fire({
      title,
      text,
      icon: "warning",
      background: `linear-gradient(180deg, ${palette.primary.main} 2%, ${palette.white} 2%, ${palette.white} 98%)`,
      iconColor: palette.primary.main,
      color: palette.black,
      confirmButtonColor: palette.primary.main,
      cancelButtonColor: colors.secondary,
      confirmButtonText: "SIM",
      cancelButtonText: "NÃO",
      showCancelButton: true,
    });
  }

  async confirmacaoGenericos(confirmacao) {
    return await ToastConfirm.fire({
      title: confirmacao?.title,
      text: confirmacao?.text,
      icon: confirmacao?.icon,
      background: `linear-gradient(180deg, ${palette.primary.main} 2%, ${palette.white} 2%, ${palette.white} 98%)`,
      iconColor: palette.primary.main,
      color: palette.black,
      confirmButtonColor: palette.primary.main,
      cancelButtonColor: colors.secondary,
      confirmButtonText: confirmacao?.confirmButtonText,
      cancelButtonText: confirmacao?.cancelButtonText,
      showCancelButton: confirmacao?.showCancelButton,
      dangerMode: confirmacao?.dangerMode || false,
    });
  }
}

export default new Notification();
